.logo_img{
    max-width: 15rem;
}

.sticky{
    background-color: white;
    width: 100vw !important;
    overflow: hidden;
    position: fixed;
    z-index: 10;
    top: 0; 

    a{
        color: gray;
        text-decoration: none;
    }
    a:hover{
        opacity: 0.5;
    }
}
