.title_div{
    margin: 3rem 0;
    color: #F73939;
}

.first_to_load{
    margin-top: 10rem;
}

.carousel_img{
    max-height: 500px;
    object-fit: contain;
}