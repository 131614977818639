.logo_footer{
    width: 10rem;
}

.container_footer1{
    background-color: lightgrey;
    margin-top: 2rem !important;
    text-align: center;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    p{
        color: black;
        font-size: medium;
    }
    a{
        text-decoration: none;
    }
}

.container_footer2{
    background-color: #000;
    padding-top: 1rem;
    color: white;
    text-align: center;
    p{
        font-size: medium;
    }
    a{
        color: white;
        text-decoration: none;
    }
}
