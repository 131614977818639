.title_div{
    margin: 3rem 0;
    color: #F73939;
}

p{
    font-size: x-large;
}

.about_img{
    //width: 100% !important;
    max-height: 20rem !important;
    object-fit: cover;
}

.carousel{
    margin-top: 10rem;
}

.carousel_img{
    max-height: 500px;
    object-fit: cover;
    //opacity: 0.7;
}

.carousel_legend{
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 150px;
    
    text-align: center;
    opacity: 0.70;
    transition: opacity .35s ease-in-out;

    h1{
        font-size: xx-large;
    }

    h2{
        font-size: x-large;
    }
}

.contamos_section{
    margin-top: 2rem;
    border-radius: 10px;
    background: lightcoral;
    color: #fff;
    padding: 10rem;

    li{
        list-style-type: none;
    }
}

.item_owl_carousel{
    object-fit: contain;
    height: 10rem !important;
}
